import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Script from 'next/script';
import TagManager from 'react-gtm-module';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Modal from '../components/Modal';
import PlayerPopup from '../components/PlayerPopup';
import BackToTop from '../components/BackToTop';
import CookiesBanner from '../components/CookiesBanner';

import { playerModalGetOpen } from '../redux/modal/playerModal.selector';
import { initGTMconsent } from '../utils/gtm';
import { getCookieConsent } from '../redux/global/global.selector';
import { loadShareThisScript } from '../utils/sharethis';
import { loadAdditionalScript } from '../utils/additionalScript';

const Layout = props => {
    const state = useSelector(state => state);

    const isPlayerModalOpen = playerModalGetOpen(state);
    const {
        header,
        footer,
        ga_id: gaId,
        // search,
        footerGroup,
        firstCompType,
        cookies,
        additionalTrackingScript,
    } = props;

    const noSplash =
        firstCompType !== 'error' && firstCompType !== 'heroBanner' && firstCompType !== 'landingPageHeroBanner';

    const cookieAccept = getCookieConsent(state);
    const [hydrated, setHydrated] = useState(false);
    useEffect(() => {
        initGTMconsent();
        setHydrated(true);
    }, []);

    useEffect(() => {
        if (cookieAccept) {
            const tagManagerArgs = {
                gtmId: gaId,
            };
            TagManager.initialize(tagManagerArgs);
            loadShareThisScript();
            if (additionalTrackingScript) {
                loadAdditionalScript(additionalTrackingScript);
            }
        }
    }, [cookieAccept]);

    return (
        <>
            <Script id='google-analytics' strategy='afterInteractive'>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments);}
                `}
            </Script>
            <Header {...header} socialMedia={footerGroup.socialMedia} noSplash={noSplash} />
            <main
                key={hydrated}
                className={['main-content', noSplash ? 'no-splash' : 'splash'].join(' ')}
                id='main-content'
            >
                {props.children}
            </main>
            <BackToTop />
            <Footer {...footer} {...footerGroup} />
            <Modal isOpen={isPlayerModalOpen} modalClass='player'>
                <PlayerPopup isModal />
            </Modal>
            <CookiesBanner {...cookies} />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    header: PropTypes.object,
    footer: PropTypes.object,
    footerGroup: PropTypes.object,
    cookies: PropTypes.object,
    ga_id: PropTypes.string,
    firstCompType: PropTypes.string,
    search: PropTypes.object,
    additionalTrackingScript: PropTypes.string
    // search: PropTypes.object,
    // footerGroup: PropTypes.object,
};

Layout.defaultProps = {
    search: {
        link: '/search',
    },
};

export default Layout;
